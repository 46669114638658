export type AnyRecord = Record<string, any>;

export interface GroupByColumn {
    name: string;
    // Include other properties of GroupByColumn if there are any
}

export interface GroupByAggregate {
    name: string;
    groupByAggregate: string;
    alias: string;
    // Include other properties of GroupByAggregate if there are any
}

declare function groupBy(data: Array<AnyRecord>, groupByColumns: Array<GroupByColumn>, groupByAggregates: Array<GroupByAggregate>): Array<AnyRecord>;

export type { groupBy };